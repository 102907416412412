import React, { useState, useRef, useEffect } from 'react';
import './style.css'
import { Image, View, TouchableOpacity, FileReader } from 'react-dom';
import { GrAddCircle,GrSubtractCircle  } from "react-icons/gr";

const PreviewImage = ({ imageSrc, width, height,x,y }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef();

  useEffect(() => {
    const image = imageRef.current;
    const imageRatio = image.naturalWidth / image.naturalHeight;
    let newScale = Math.min(width / image.naturalWidth, height / image.naturalHeight);
    if(newScale== Infinity){
      setScale(1);
    }else {
      setScale(newScale);
      setPosition({
        x: (width - image.naturalWidth * newScale) / 2,
        y: (height - image.naturalHeight * newScale) / 2,
      });
    }
   
  }, [imageSrc, width, height]);

  const handleMoveUp = () => setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y - 10 }));
  const handleMoveDown = () => setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y + 10 }));
  const handleMoveLeft = () => setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x - 10 }));
  const handleMoveRight = () => setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x + 10 }));
  
  const handleZoomIn = () => setScale(scale + 0.1);
  const handleZoomOut = () => setScale(scale - 0.1);
  useEffect(() => {
    const image = imageRef.current;
    image.style.transform = `scale(${scale}) translate(${position.x}px, ${position.y}px)`;
  }, [position, scale]);

  return (
    <div style={{ position: 'absolute', width, height, overflow: 'hidden',background:'#d1d0d0',top:y, left:x }}>
      <img
        ref={imageRef}
        src={imageSrc}
        alt="Uploaded Image"
        style={{width,height}}
      />
      <div className="Buttons">
        <button onClick={handleMoveUp}>↑</button>
        <button onClick={handleMoveDown}>↓</button>
        <button onClick={handleMoveLeft}>←</button>
        <button onClick={handleMoveRight}>→</button>
      </div>
      <div className="Buttons zoomBtn">
        <GrAddCircle onClick={handleZoomIn} />
        <GrSubtractCircle onClick={handleZoomOut} />
      </div>
    </div>
  );
};

export default PreviewImage;