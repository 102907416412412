import ApiService from "../services/ApiService";
import { Constants } from "../config";


const SANDBOX = false;
const paymentGateway = {
    loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script); 
      });
    },
  
    async displayRazorpay(state, isDonation = false, onSuccess,onError) {
      if (state.totalPrice < 1) {
        // Implement saveImagesToProfile logic here
        return;
      }
      state.loader_message = "Please wait while we initiate the payment procedure";
      state.is_loading = true;
  
      const res = await this.loadScript(SANDBOX ?  "https://checkout.razorpay.com/v1/checkout.js":
        "https://checkout.razorpay.com/v1/checkout.js"
      );
  
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
  
      const postParams = {
        total_price: this.gstPrice(state,isDonation),
      };
  
      console.log('POST Param',postParams)
      return ApiService.postData("createOrder", postParams, true).then((response) => {
        if (response.status_code == 200) {
          return this.openPaymentGateway(response.data, state, isDonation, onSuccess,onError);
        }
      });
    },
  
    gstPrice(state,isDonation) {
        if(isDonation) return state.totalPrice;
        let totalAmount = parseFloat(state.totalPrice);
      const gstAmount = (totalAmount * 18) / 100;
      return Math.round(totalAmount + gstAmount);
    },
  
    openPaymentGateway(data, state, isDonation, onSuccess,onError) {
      const campaignData = state.campaignData;
      const options = {
        key: SANDBOX ? Constants.RAZORPAY_KEY_DEV : Constants.RAZORPAY_KEY,
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: campaignData?.title ? campaignData?.title : "Eventforce",
        description: "Thank you",
        image: campaignData?.logo,
        handler: (response) => {
          if (response) {
            state.loader_message = "Please wait while we prepare your photos";
            const imagesArr = state.selectedImages.map((a) => a.id);
            const paymentDetails = {
              amount: data.amount.toString(),
              gateway: "razorpay",
              transaction_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              payment_id: response.razorpay_payment_id,
              gateway_response: response,
              status: 1,
            };
  
            const postParams = {
              payment_gateway_response: JSON.stringify(paymentDetails),
              status: 1,
              transaction_id: response.razorpay_payment_id,
              images: JSON.stringify(imagesArr),
              total_qty: state.selectedImages.length,
              total_price: isDonation ? state.totalPrice : this.gstPrice(state),
              gateway_amount: data.amount,
              image_console_id: campaignData.image_console_id,
              bib_number: state.bidNumber,
            };
  
            ApiService.postData("createPhotoOrder", postParams, true).then(
              (response) => {
                if (response.status == "success") {
                   onSuccess(response);
                } else {
                }
              }
            );
          }
        },
        modal: {
          ondismiss: () => {
            alert("Payment cancelled. Try again");
            onError('Cancelled');
            state.paymentBtnLoading = false;
          },
        },
        prefill: {
          name: state.user_name,
          email: state.user_email,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };
  
  export default paymentGateway;
  