export const createImage = "campaign/image/create";
export const getUserPosts = "campaign/user/posts";
export const googleLogin = "login/google";
export const facebookLogin = "login/facebook";
export const getRegisteredUsers = "campaign/users";
export const getAllPosts = "campaign/posts";
export const getCampaignTemplates = "template/getByCampaignId";
export const getByCampaignFriendlyName = "campaign/getByCampaignFriendlyName";
export const checkIfCampaignActive = "campaign/checkIfCampaignActive";
export const createParticipantEnrollment = "participantEnrolment/create";
export const updateParticipantEnrolmentPayment =
  "participantEnrolment/updatePayment";
export const getUserParticipants = "participant/getParticipants";
export const getAllParticipants = "participant/getAllParticipants";
export const addParticipantVideo = "participant/addParticipantVideo";
export const createUserFileUploadLog = "userLog/createUserFileUploadLog";
export const getCampaignImagesByName = "campaign/getCampaignImagesByName";

// Photoapp
export const getLowResPhotosByBidNo = "photo/getLowResPhotosByBidNo";
export const createPhotoOrder = "photoOrder/create";
export const getMyPhotos = "photo/getMyPhotos";
export const searchEvent = "event/searchEvent";
export const getByEventFriendlyName = "event/getByEventFriendlyName";

export const getCompanies = 'company/getAll';
export const completeProfile = 'user/profileUpdate';
export const updateProfileImage = 'user/profileImageUpdate';
export const getParticipant = 'participantType/get';
export const getCapaign ='campaign/getType';
export const uploadImages = 'user/saveImages';
export const savedImages = 'user/savedImages';
export const deleteImage = 'user/deleteImage';

export const createOrder = 'payment/createOrder';
export const paymentHistory = 'payment/getPurchaseHistory';

export const checkPrebook = 'user/checkPrebook';





